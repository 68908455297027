import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import RemoteLock from './RemoteLock';
import Whatsapp from './Whatsapp';

import './style.css';
import RemoteLockInitiate from "./RemoteLock/RemoteLockInitiate";
import HostfullyInitiate from "./Hostfully/HostfullyInitiate";

function IntgConfigure(props) {

  return (
    <>
      <Routes>
        <Route element={<Outlet />}>
          <Route path="remote-lock" element={<RemoteLock />} />
          <Route path="remote-lock-initiate" element={<RemoteLockInitiate />} />
          <Route path="hostfully-initiate" element={<HostfullyInitiate />} />
          <Route path="whatsapp" element={<Whatsapp />} />
        </Route>
      </Routes>
    </>
  );

}

export default IntgConfigure;
