// @flow

import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import Select from 'react-select';
import queryString from 'query-string';
import Pagination from 'rc-pagination';

import { setCommonParams } from '../../../../../redux/actions/appActions';
import { getDataAction } from '../../../../../redux/actions/getDataActions';

import { DATE_DURATION_OPTIONS, formatDate, PN_TO_ID,
  FEEDBACK_SOURCE_TO_LABEL, DATE_DURATION_OPTIONS_MAP, getUserGAId } from '../../../../../helpers';

import SingleSent from './SingleSent';

import useOnClickOutside from '../../../../Misc/OutsideClickHook';

import './style.css';
import 'antd/dist/antd.css';
import "rc-pagination/assets/index.css";

import searchIcon from '../../../../../img/icons/search.svg';
import closeIcon from '../../../../../img/icons/close.svg';
import downloadIcon from '../../../../../img/icons/download.svg';

const { RangePicker } = DatePicker;

const gtconfig = require('../../../../../gtconfig');

var Scroll   = require('react-scroll');
var scroll = Scroll.animateScroll;

function FilterRow(props) {
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);
  const onCheckBoxChange = (e) => {
    if (props.data.id === 'all' && selected) return;
    setSelected(selected => !selected);
    props.onRowClick(props.type, props.data);
  };
  const onLabelClick = (e) => {
    if (props.data.id === 'all' && selected) return;
    setSelected(selected => !selected);
    props.onRowClick(props.type, props.data);
  };
  return (
    <div className="dRow">
      <div className="left">
        <input type="checkbox" name={`filter_ota_${props.data.id}`} onChange={onCheckBoxChange} value={selected ? 'on' : 'off'} checked={selected} />
        <div className="label" onClick={onLabelClick}>
          {props.data.title}
        </div>
      </div>
      <div className="right">
        <div className="count">
          ({props.data.value || 0})
        </div>
      </div>
    </div>
  );
}

function RequestSent(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  const location = useLocation();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [dateRangeLastMonthValue, setDateRangeLastMonthValue] = useState(
    { label: 'Last 1 Month', value: 'last-1-month' }
  );

  const [dateFilterStartDate, setDateFilterStartDate] = useState(new Date(moment().subtract(1, 'month')));
  const [dateFilterEndDate, setDateFilterEndDate] = useState(new Date());
  const [dateRangeActive, setDateRangeActive] = useState(false);

  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);

  const [filterKeyword, setFilterKeyword] = useState('');
  const [filterKeywordActive, setFilterKeywordActive] = useState('');
  const [filterPositiveNegativeOptions, setFilterPositiveNegativeOptions] = useState([]);
  const [positiveNegativeFilterValues, setPositiveNegativeFilterValues] = useState({'all': true});
  const [filterSourceOptions, setFilterSourceOptions] = useState([]);
  const [sourceFilterValues, setSourceFilterValues] = useState({'all': true});
  const [filterActive, setFilterActive] = useState(false);

  const getFeedbacksSentData = (startDate, endDate, keyword, positiveNegativeFilter, sourceFilter, pageNumber) => {
    dispatch(getDataAction({
      op: 'get_feedback_sent_data',
      params: {
        location__id: urlParams.locationidentifier,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        keyword,
        positive_negative_filter: positiveNegativeFilter,
        source_filter: sourceFilter,
        skip: pageSize * (pageNumber - 1) >= 0 ? pageSize * (pageNumber - 1) : 0,
        limit: pageSize,
      }
    }));
    setTimeout(() => {
      scroll.scrollToTop({
        duration: 100,
        delay: 0,
        smooth: true,
        // containerId: 'app',
        // offset: 50, // Scrolls to element + 50 pixels down the page
      });
    }, 300);
  };

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Reputation - Feedbacks - Requests Sent - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {

    const parsed = queryString.parse(location.search);
    if (process.env.NODE_ENV !== 'production') {
      console.log('FeedbackOverview parsed: ', parsed);
    }
    if (parsed['keyword']) {
      const keyword = parsed['keyword'];
      if (keyword) {
        setFilterKeyword(keyword);
        setFilterActive(true);
      } else {
        setFilterActive(true);
      }
    }
    let tempPositiveNegativeFilterValues = {};
    if (parsed['positive_negative']) {
      const positive_negative = parsed['positive_negative'];
      let positiveNegativeList = positive_negative.split(',');
      positiveNegativeList.forEach((item) => {
        if (item && PN_TO_ID[item]) {
          tempPositiveNegativeFilterValues[item] = true;
        }
      });
    } else {
      tempPositiveNegativeFilterValues = { 'all': true };
    }
    setPositiveNegativeFilterValues(tempPositiveNegativeFilterValues);

    let tempSourceFilterValues = {};
    if (parsed['source']) {
      const source = parsed['source'];
      let sourceList = source.split(',');
      sourceList.forEach((item) => {
        if (item && FEEDBACK_SOURCE_TO_LABEL[item]) {
          tempSourceFilterValues[item] = true;
        }
      });
    } else {
      tempSourceFilterValues = { 'all': true };
    }
    setSourceFilterValues(tempSourceFilterValues);

    let tempPageNumber = '';
    if (parsed['page_number']) {
      tempPageNumber = parsed['page_number'];
      if (tempPageNumber && Number.isInteger(parseInt(tempPageNumber))) {
        setPageNumber(tempPageNumber);
      }
    }
    if (!tempPageNumber) tempPageNumber = 1;

    let tempPositiveNegativeOptions = [];
    tempPositiveNegativeOptions.push({ id: 'all', title: 'All' });
    tempPositiveNegativeOptions.push({ id: 'positive', title: 'Positive' });
    tempPositiveNegativeOptions.push({ id: 'negative', title: 'Negative' });
    setFilterPositiveNegativeOptions(tempPositiveNegativeOptions);

    let tempFilterOptions = [];
    tempFilterOptions.push({ id: 'all', title: 'All' });
    tempFilterOptions.push({ id: 'upload', title: 'Upload' });
    tempFilterOptions.push({ id: 'automated', title: 'Automated' });
    tempFilterOptions.push({ id: 'instant_invite', title: 'Instant Invite' });
    setFilterSourceOptions(tempFilterOptions);

    const { commonParams } = appState;
    let startDate = commonParams && commonParams.startDate ? new Date(commonParams.startDate) : new Date(moment().subtract(1, 'month'));
    let endDate = commonParams && commonParams.endDate ? new Date(commonParams.endDate) : new Date();
    let rangeKeyword = commonParams && commonParams.rangeKeyword ? commonParams.rangeKeyword : 'last-1-month';
    let dateUpdated = commonParams ? commonParams.dateUpdated || new Date(moment().subtract(3, 'days')) : new Date(moment().subtract(3, 'days'));
    if (moment().diff(dateUpdated, 'hours') > 24) {
      startDate = new Date(moment().subtract(1, 'month'));
      endDate = new Date();
    }
    setDateFilterStartDate(startDate);
    setDateFilterEndDate(endDate);
    setDateRangeLastMonthValue({ label: DATE_DURATION_OPTIONS_MAP[rangeKeyword], value: rangeKeyword });

    getFeedbacksSentData(startDate, endDate, parsed['keyword'], tempPositiveNegativeFilterValues, tempSourceFilterValues, tempPageNumber);
    if (parsed['keyword'] || !tempPositiveNegativeFilterValues.all || !tempSourceFilterValues.all) {
      setFilterActive(true);
    } else {
      setFilterActive(false);
    }
  }, [urlParams.locationidentifier]);

  const onSearchChange = (e) => {
    const { name, value } = e.target;
    setFilterKeyword(value);
  };
  const onFilterSearchClick = (e) => {
    if (filterKeyword) {
      setFilterActive(true);
      setPageNumber(1);
      const parsed = queryString.parse(location.search);
      parsed['keyword'] = filterKeyword;
      if (parsed['page_number']) delete parsed['page_number'];
      const stringified = queryString.stringify(parsed);
      navigate(`${location.pathname}?${stringified}`);
      getFeedbacksSentData(dateFilterStartDate, dateFilterEndDate, filterKeyword, positiveNegativeFilterValues, sourceFilterValues, 1);
      setFilterKeywordActive(filterKeyword);
    }
  };
  const onFilterSearchInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (filterKeyword) {
        setFilterActive(true);
        setPageNumber(1);
        const parsed = queryString.parse(props.location.search);
        parsed['keyword'] = filterKeyword;
        if (parsed['page_number']) delete parsed['page_number'];
        const stringified = queryString.stringify(parsed);
        navigate(`${location.pathname}?${stringified}`);
        getFeedbacksSentData(dateFilterStartDate, dateFilterEndDate, filterKeyword, positiveNegativeFilterValues, sourceFilterValues, 1);
        setFilterKeywordActive(filterKeyword);
      }
    }
    if (e.keyCode === 27) {
      setFilterKeyword('');
    }
  };
  const onFilterSearchClearClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onFilterSearchClearClick');
    }
    setFilterKeyword('');
  };


  const onDateRangeChange = (dates, dateStrings) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDateRangeChange dates: ', dates);
      console.log('onDateRangeChange dateStrings: ', dateStrings);
    }
    const { feedbacks } = props.reputation;
    if (!feedbacks || !feedbacks.sent_data || feedbacks.sent_data.getInProgress) return;
    setDateRangeActive(true);
    setDateFilterStartDate(new Date(dates[0]));
    setDateFilterEndDate(new Date(dates[1]));
    setPageNumber(1);
    const parsed = queryString.parse(props.location.search);
    if (parsed['page_number']) delete parsed['page_number'];
    const stringified = queryString.stringify(parsed);
    navigate(`${location.pathname}?${stringified}`);
    dispatch(setCommonParams({
      params: {
        rangeKeyword: '',
        startDate: new Date(dates[0]),
        endDate: new Date(dates[1]),
        dateUpdated: new Date(),
      },
    }));
    getFeedbacksSentData(dates[0], dates[1], filterKeyword, positiveNegativeFilterValues, sourceFilterValues, 1);
    // analytics event reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Feedback Requests Sent - Date Change Range`,
        'event_label': `Date Change Range - ${dateStrings}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Feedback Requests Sent - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onDateRangeLastMonthChange = (value) => {
    const { feedbacks } = props.reputation;
    if (!feedbacks || !feedbacks.sent_data || feedbacks.sent_data.getInProgress) return;
    setPageNumber(1);
    const parsed = queryString.parse(location.search);
    if (parsed['page_number']) delete parsed['page_number'];
    const stringified = queryString.stringify(parsed);
    navigate(`${location.pathname}?${stringified}`);
    setDateRangeActive(false);
    setDateRangeLastMonthValue(value);
    const rangeValue = value.value;
    if (rangeValue.indexOf('-day') > -1) {
      let period = rangeValue.split('-day')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'days')))
      setDateFilterEndDate(new Date());
      dispatch(setCommonParams({
        params: {
          rangeKeyword: rangeValue,
          startDate: new Date(moment().subtract(period, 'days')),
          endDate: new Date(),
          dateUpdated: new Date(),
        },
      }));
      getFeedbacksSentData(new Date(moment().subtract(period, 'days')), new Date(), filterKeyword, positiveNegativeFilterValues, sourceFilterValues, 1);
    }
    if (rangeValue.indexOf('-month') > -1) {
      let period = rangeValue.split('-month')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'month')))
      setDateFilterEndDate(new Date());
      dispatch(setCommonParams({
        params: {
          rangeKeyword: rangeValue,
          startDate: new Date(moment().subtract(period, 'month')),
          endDate: new Date(),
          dateUpdated: new Date(),
        },
      }));
      getFeedbacksSentData(new Date(moment().subtract(period, 'month')), new Date(), filterKeyword, positiveNegativeFilterValues, sourceFilterValues, 1);
    }
    // analytics event reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Feedback Requests Sent - Date Change Last`,
        'event_label': `Date Change Last - ${rangeValue}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Feedback Requests Sent - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onFilterResetClick = (e) => {
    setSourceFilterValues({ 'all': true });
    setFilterKeyword('');
    setFilterActive(false);
    const parsed = queryString.parse(location.search);
    delete parsed['keyword'];
    delete parsed['source'];
    const stringified = queryString.stringify(parsed);
    navigate(`${location.pathname}?${stringified}`);
    getFeedbacksSentData(dateFilterStartDate, dateFilterEndDate, '', { 'all': true }, { 'all': true }, pageNumber);
    setFilterKeywordActive('');
  };

  const onFilterRowClick = (type, row) => {
    if (type === 'source') {
      let tempSourceFilterValues = { ...sourceFilterValues };
      if (row.id !== 'all') {
        if (tempSourceFilterValues[row.id]) {
          delete tempSourceFilterValues[row.id];
          if (!Object.keys(tempSourceFilterValues).length) tempSourceFilterValues = { 'all': true };
        } else {
          tempSourceFilterValues[row.id] = true;
          delete tempSourceFilterValues['all'];
        }
        setFilterActive(true);
      } else {
        tempSourceFilterValues = { 'all': true };
        setFilterActive(false);
      }
      getFeedbacksSentData(dateFilterStartDate, dateFilterEndDate, filterKeyword, positiveNegativeFilterValues, tempSourceFilterValues, 1);
      setSourceFilterValues(tempSourceFilterValues);
      const parsed = queryString.parse(props.location.search);
      parsed['source'] = Object.keys(tempSourceFilterValues).reduce((accumulator, currentValue) => {
        return `${currentValue},${accumulator}`;
      }, '');
      if (parsed['page_number']) delete parsed['page_number'];
      const stringified = queryString.stringify(parsed);
      navigate(`${location.pathname}?${stringified}`);
      setPageNumber(1);
    }
    if (type === 'positive_negative') {
      let tempPositiveNegativeFilterValues = { ...positiveNegativeFilterValues };
      if (row.id !== 'all') {
        if (tempPositiveNegativeFilterValues[row.id]) {
          delete tempPositiveNegativeFilterValues[row.id];
          if (!Object.keys(tempPositiveNegativeFilterValues).length) tempPositiveNegativeFilterValues = { 'all': true };
        } else {
          tempPositiveNegativeFilterValues[row.id] = true;
          delete tempPositiveNegativeFilterValues['all'];
        }
        setFilterActive(true);
      } else {
        tempPositiveNegativeFilterValues = { 'all': true };
        setFilterActive(false);
      }
      getFeedbacksSentData(dateFilterStartDate, dateFilterEndDate, filterKeyword, tempPositiveNegativeFilterValues, sourceFilterValues, 1);
      setPositiveNegativeFilterValues(tempPositiveNegativeFilterValues);
      const parsed = queryString.parse(props.location.search);
      parsed['positive_negative'] = Object.keys(tempPositiveNegativeFilterValues).reduce((accumulator, currentValue) => {
        return `${currentValue},${accumulator}`;
      }, '');
      if (parsed['page_number']) delete parsed['page_number'];
      const stringified = queryString.stringify(parsed);
      navigate(`${location.pathname}?${stringified}`);
      setPageNumber(1);
    }
  };

  const onPageChange = (page) => {
    setPageNumber(page);
    getFeedbacksSentData(dateFilterStartDate, dateFilterEndDate, filterKeyword, positiveNegativeFilterValues, sourceFilterValues, page);
    const parsed = queryString.parse(props.location.search);
    parsed['page_number'] = page;
    const stringified = queryString.stringify(parsed);
    navigate(`${location.pathname}?${stringified}`);
  };

  const onPaginationShowSizeChange = (current, pageSize) => {
    console.log('onPaginationShowSizeChange: ', current, ' # ', pageSize);
  };

  const onDownloadClick = (e) => {
    const { feedbacks } = props.reputation;
    if (feedbacks) {
      if (feedbacks.getFeedbackSentDumpInProgress) return;
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      let filename = "Feedbacks-Sent";
      if (locationName) { filename += "-" + locationName.replace(/ /g, ''); }
      if (dateFilterStartDate) { filename += "-" + moment(dateFilterStartDate).format('YYYY-MM-DD') }
      if (dateFilterEndDate) { filename += "-to-" + moment(dateFilterEndDate).format('YYYY-MM-DD') }
      filename += ".csv";
      dispatch(getDataAction({
        op: 'get_feedback_sent_dump',
        params: {
          location__id: urlParams.locationidentifier,
          start_date: moment(dateFilterStartDate).format('YYYY-MM-DD'),
          end_date: moment(dateFilterEndDate).format('YYYY-MM-DD'),
          keyword: filterKeyword,
          filename,
          positive_negative_filter: positiveNegativeFilterValues,
          source_filter: sourceFilterValues,
        },
      }));
      window.gtag('event', 'gt_click', {
        'event_category': `Feedback Requests Sent - Download CSV`,
        'event_label': `Download CSV`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Feedback Requests Sent - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    }
  };

  const renderDateFilterRow = (feedbacks, overallCount) => {
    const has_csv_download_feedbacks = props.locationInfo;
    return (
      <div className="dtFltBx">
        <div className="left">
          <div className="title">
            Requests Sent ({overallCount})
          </div>
        </div>
        <div className="dateFilter">
          { has_csv_download_feedbacks && overallCount > 0 &&
            <div className="dwnldCsv" onClick={onDownloadClick}>
              <img src={downloadIcon} />
              <p className="download">Download CSV</p>
              {
                feedbacks && feedbacks.getFeedbackSentDumpInProgress &&
                <span>
                  <i className="fa fa-spinner fa-spin" />
                </span>
              }
            </div>
          }
          <div className="dateFilterLastMonth">
            <Select
              className="dateFilterLastMonthSelect"
              options={DATE_DURATION_OPTIONS}
              onChange={onDateRangeLastMonthChange}
              value={dateRangeLastMonthValue}
            />
          </div>
          <div className="dateFilterRange">
            <RangePicker
              value={[moment(dateFilterStartDate), moment(dateFilterEndDate)]}
              onChange={onDateRangeChange}
              format="DD MMM YYYY"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderPagination = (feedbacks, overallCount) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('renderPagination pageNumber: ', pageNumber);
    }
    // if (!keyValue || !keyValue.filter_count) return null;
    // let totalCount = 0;
    // totalCount = keyValue.filter_count || 0;
    // console.log('totalCount final: ', totalCount);
    if (!overallCount) return null;
    return (
      <div className="rpPagination">
        <Pagination
          onChange={onPageChange}
          // defaultCurrent={pageNumber}
          current={pageNumber}
          total={overallCount}
          pageSize={pageSize}
          hideOnSinglePage={true}
          showSizeChanger
          onShowSizeChange={onPaginationShowSizeChange}
        />
      </div>
    );
  };

  const renderFilterPanel = (feedbacks) => {
    if (!feedbacks) return null;
    return (
      <div className="filterPanel">
        <div className="header">
          <p>Filters</p>
          <p className="reset" onClick={onFilterResetClick}>
            <span><i className="fa fa-redo-alt" /></span>
            Reset
          </p>
        </div>
        <div className="search">
          <img className="label" src={searchIcon} />
          <input type="text"
            placeholder="Type any keyword..."
            onChange={onSearchChange}
            name="searchKeyword"
            onKeyDown={onFilterSearchInputKeyDown}
            value={filterKeyword}
          />
          {
            filterKeyword &&
            <img className="clear" src={closeIcon} onClick={onFilterSearchClearClick} />
          }
          <span className="submit" onClick={onFilterSearchClick}><i className="fa fa-arrow-right" /></span>
        </div>
        <div className="filterBox otaFilter">
          <div className="title">
            Source
          </div>
          <div className="dRows">
            {
              filterSourceOptions.map((item) => {
                if (feedbacks.sent && feedbacks.sent.source_split) {
                  item.value = feedbacks.sent.source_split[item.id].value;
                }
                // console.log('item: ', item);
                return (
                  <FilterRow type="source" data={item} onRowClick={onFilterRowClick} selected={sourceFilterValues[item.id]} />
                )
              })
            }
          </div>
        </div>
      </div>
    );
  };

  const renderNoData = () => {
    return (
      <div className="noDataBox">
        <p className="title">No feedback requests sent!</p>
      </div>
    );
  };

  const renderContent = (feedbacks, overallCount) => {
    const { locationidentifier } = urlParams;
    let positivePercent = 0, negativePercent = 0;



    return (
      <div className="fbdSntBd">
        <div className="lt">
          <div className="innerBox">
            <div className="contentBox">
              {
                overallCount
                ?
                  <div className="dataBox">
                    <div className="listBox">
                      {
                        feedbacks && feedbacks.sent && feedbacks.sent.list
                        ?
                          feedbacks.sent.list.map((item) => {
                            return <SingleSent key={`sfb_${item._id}`} data={item} filterKeyword={filterKeywordActive}/>
                          })
                        : null
                      }
                    </div>
                    { renderPagination(feedbacks, overallCount) }
                  </div>
                : renderNoData()
              }
            </div>
          </div>
        </div>
        <div className="rt">
          { overallCount || filterActive ? renderFilterPanel(feedbacks) : null }
        </div>
      </div>
    )
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('RequestSent props: ', props);
  }

  const { feedbacks } = props.reputation;
  if (!feedbacks || !feedbacks.sent_data) return null;
  const { sent_data } = feedbacks;

  let overallCount = 0;
  if (sourceFilterValues.all) {
    if (sent_data && sent_data.sent && sent_data.sent.source_split && sent_data.sent.source_split.all) {
      overallCount = sent_data.sent.source_split.all.value;
    }
  } else {
    overallCount = 0;
  }
  if (sourceFilterValues.automated) {
    overallCount += sent_data.sent.source_split['automated'].value;
  }
  if (sourceFilterValues.instant_invite) {
    overallCount += sent_data.sent.source_split['instant_invite'].value;
  }
  if (sourceFilterValues.upload) {
    overallCount += sent_data.sent.source_split['upload'].value;
  }

  return (
    <>
      {
        feedbacks.sent_data.getInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      }
      <div className="gPScrlWrp flHt mk">
        <div className="gPg fdbSntPg">
          { renderDateFilterRow(feedbacks, overallCount) }
          { renderContent(feedbacks.sent_data, overallCount) }
        </div>
      </div>
    </>
  );
}

export default RequestSent;
