import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, DatePicker } from 'antd';
import moment, { months } from 'moment';
import Select from 'react-select';
import queryString from 'query-string';
import Pagination from 'rc-pagination';


import ToolTipWrapper from '../../../Misc/ToolTipWrapper';
import SingleFeedback from './SingleFeedback/SingleFeedback';

import { setCommonParams } from '../../../../redux/actions/appActions';
import { getDataAction } from '../../../../redux/actions/getDataActions';

import { DATE_DURATION_OPTIONS_MAP, OTA_ORDER, OTA_NAME_TO_SOURCE_MAP, OTA_ID_TO_NAME_FORMAL_MAP,
  renderOTALogo, REVIEW_TABS_TO_RS_MAP, OTA_SOURCE_TO_NAME_MAP, getReviewState,
  ratingToInt, RS, RATING_TO_STRING_MAP, REVIEW_TABS_MAP,
  getReputationAccess, getUserGAId, isRatingAGreaterThanB } from '../../../../helpers';

import searchIcon from '../../../../img/icons/search.svg';
import closeIcon from '../../../../img/icons/close.svg';
import downloadIcon from '../../../../img/icons/download.svg';

import './style.css';

var Scroll   = require('react-scroll');
var scroll = Scroll.animateScroll;

const { RangePicker } = DatePicker;

const gtconfig = require('../../../../gtconfig');

const lastMonthOptions = [];
lastMonthOptions.push({ label: 'Select', value: 'select' });
lastMonthOptions.push({ label: 'Last 7 days', value: 'last-7-days' });
lastMonthOptions.push({ label: 'Last 15 days', value: 'last-15-days' });
lastMonthOptions.push({ label: 'Last 1 Month', value: 'last-1-month' });
lastMonthOptions.push({ label: 'Last 3 Months', value: 'last-3-months' });
// lastMonthOptions.push({ label: 'Last 6 Months', value: 'last-6-months' });
// lastMonthOptions.push({ label: 'Last 12 Months', value: 'last-12-months' });


function ReviewTabButton(props) {
  const onButtonClick = (e) => { props.onButtonClick(props.data); }
  return (
    <span className={'reviewTabButton ' + (props.active ? 'active' : '')} onClick={onButtonClick}>
      {props.data.title} { props.data.count ? `(${props.data.count})` : ''}
    </span>
  );
}

function FilterRow(props) {
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);
  const onCheckBoxChange = (e) => {
    if (props.data.id === 'all' && selected) return;
    setSelected(selected => !selected);
    props.onRowClick(props.type, props.data);
  };
  const onLabelClick = (e) => {
    if (props.data.id === 'all' && selected) return;
    setSelected(selected => !selected);
    props.onRowClick(props.type, props.data);
  };
  return (
    <div className="dRow">
      <div className="left">
        <input type="checkbox" name={`filter_ota_${props.data.id}`} onChange={onCheckBoxChange} value={selected ? 'on' : 'off'} checked={selected} />
        <div className="label" onClick={onLabelClick}>
          {props.data.title}
        </div>
      </div>
      {/* <div className="right">
        <div className="count">
          ({props.data.count || 0})
        </div>
      </div> */}
    </div>
  );
}

function Trend(props) {
  const appState = useSelector((state) => state.appReducer);
  const userState = useSelector((state) => state.userReducer);
  const windowState = useSelector((state) => state.windowReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  const location = useLocation();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [dateRangeLastMonthValue, setDateRangeLastMonthValue] = useState(
    { label: 'Last 1 Month', value: 'last-1-month' }
  );
  const [dateFilterStartDate, setDateFilterStartDate] = useState(new Date(moment().subtract(1, 'month')));
  const [dateFilterEndDate, setDateFilterEndDate] = useState(new Date());

  const [showDaily, setShowDaily] = useState(false);
  const [byPeriod, setByPeriod] = useState('weekly');

  const getData = (startDate, endDate) => {
    dispatch(getDataAction({
      op: 'get_trend_data',
      params: {
        location__id: urlParams.locationidentifier,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      },
    }));
    const diffDays = moment(endDate).diff(moment(startDate), 'days');
    setShowDaily(diffDays > 10 ? false : true);

    setTimeout(() => {
      scroll.scrollToTop({
        duration: 100,
        delay: 0,
        smooth: true,
        // containerId: 'app',
        // offset: 50, // Scrolls to element + 50 pixels down the page
      });
    }, 300);
  };

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: `Reputation - Trends - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('useEffect locationidentifier: ', urlParams.locationidentifier);
    }
    const { commonParams } = appState;
    let startDate = commonParams && commonParams.startDate ? new Date(commonParams.startDate) : new Date(moment().subtract(1, 'month'));
    let endDate = commonParams && commonParams.endDate ? new Date(commonParams.endDate) : new Date();
    let rangeKeyword = commonParams && commonParams.rangeKeyword ? commonParams.rangeKeyword : 'last-1-month';
    let dateUpdated = commonParams ? commonParams.dateUpdated || new Date(moment().subtract(3, 'days')) : new Date(moment().subtract(3, 'days'));
    if (moment().diff(dateUpdated, 'hours') > 24) {
      startDate = new Date(moment().subtract(1, 'month'));
      endDate = new Date();
    }

    setDateFilterStartDate(startDate);
    setDateFilterEndDate(endDate);
    setDateRangeLastMonthValue({ label: DATE_DURATION_OPTIONS_MAP[rangeKeyword], value: rangeKeyword });
    getData(startDate, endDate);

  }, [urlParams.locationidentifier]);


  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('useEffect props.reputation.trend: ', props.reputation.trend);
    }
  }, [props.reputation.trend]);

  const onDateRangeLastMonthChange = (value) => {
    setDateRangeLastMonthValue(value);
    const rangeValue = value.value;
    if (rangeValue.indexOf('-day') > -1) {
      let period = rangeValue.split('-day')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'days')))
      setDateFilterEndDate(new Date());
      dispatch(setCommonParams({
        params: {
          rangeKeyword: rangeValue,
          startDate: new Date(moment().subtract(period, 'days')),
          endDate: new Date(),
          dateUpdated: new Date(),
        },
      }));
      // getReviewsData(new Date(moment().subtract(period, 'days')), new Date());
      getData(new Date(moment().subtract(period, 'days')), new Date());
    }
    if (rangeValue.indexOf('-month') > -1) {
      let period = rangeValue.split('-month')[0];
      if (period.indexOf('last-') > -1) period = period.split('last-')[1];
      setDateFilterStartDate(new Date(moment().subtract(period, 'month')))
      setDateFilterEndDate(new Date());
      dispatch(setCommonParams({
        params: {
          rangeKeyword: rangeValue,
          startDate: new Date(moment().subtract(period, 'month')),
          endDate: new Date(),
          dateUpdated: new Date(),
        },
      }));
      // getReviewsData(new Date(moment().subtract(period, 'month')), new Date());
      getData(new Date(moment().subtract(period, 'month')), new Date());
    }
    // analytics event reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Trends - Date Change Last`,
        'event_label': `Date Change Last - ${rangeValue}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Reviews - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const onDateRangeChange = (dates, dateStrings) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onDateRangeChange dates: ', dates);
      console.log('onDateRangeChange dateStrings: ', dateStrings);
    }
    const { trend } = props.reputation;
    if (!trend || trend.getInProgress) return;
    // setDateRangeActive(true);
    setDateFilterStartDate(new Date(dates[0]));
    setDateFilterEndDate(new Date(dates[1]));
    setDateRangeLastMonthValue({ label: 'Select', value: 'select' });
    dispatch(setCommonParams({
      params: {
        rangeKeyword: 'select',
        startDate: new Date(dates[0]),
        endDate: new Date(dates[1]),
        dateUpdated: new Date(),
      },
    }));
    // getReviewsData(dates[0], dates[1]);
    getData(dates[0], dates[1]);
    // analytics event reporting
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : ''
      window.gtag('event', 'gt_click', {
        'event_category': `Reputation - Trends - Date Change Range`,
        'event_label': `Date Change Range - ${dateStrings}`,
        page_path: `${location.pathname}`,
        page_title: `Reputation - Trends - ${locationName}`,
        gt_user_id: getUserGAId(userState.user),
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  };

  const byMonthlyClick = (e) => {
    setByPeriod('monthly');
  };
  const byWeeklyClick = (e) => {
    setByPeriod('weekly');
  };
  const byDailyClick = (e) => {
    setByPeriod('daily');
  };

  
  // const onDownloadClick = (e) => {
  //   const { reviews } = props.reputation;
  //   if (reviews) {
  //     if (reviews.getReviewsDumpInProgress) return;
  //     const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
  //       && locationState.locations[urlParams.locationidentifier].settings
  //       && locationState.locations[urlParams.locationidentifier].settings.info
  //       ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
  //       : ''
  //     let filename = "Reviews";
  //     if (locationName) { filename += "-" + locationName.replace(/ /g, ''); }
  //     if (dateFilterStartDate) { filename += "-" + moment(dateFilterStartDate).format('YYYY-MM-DD') }
  //     if (dateFilterEndDate) { filename += "-to-" + moment(dateFilterEndDate).format('YYYY-MM-DD') }
  //     filename += ".csv";
  //     dispatch(getDataAction({
  //       op: 'get_reviews_dump',
  //       params: {
  //         location__id: urlParams.locationidentifier,
  //         review_state: selectedTab,
  //         start_date: moment(dateFilterStartDate).format('YYYY-MM-DD'),
  //         end_date: moment(dateFilterEndDate).format('YYYY-MM-DD'),
  //         keyword: filterKeyword,
  //         ota_filter: otaFilterValues,
  //         rating_filter: ratingFilterValues,
  //         filename,
  //       },
  //     }));
  //     // props.dispatch(getReviewsDumpAction({
  //     //   params: {
  //     //     location__id: locationidentifier,
  //     //     start_date: moment(dateFilterStartDate).format('YYYY-MM-DD'),
  //     //     end_date: moment(dateFilterEndDate).format('YYYY-MM-DD'),
  //     //     keyword: filterKeyword,
  //     //     filename,
  //     //     review_state: selectedTab,
  //     //     ota_filter: otaFilterValues,
  //     //     rating_filter: ratingFilterValues,
  //     //   },
  //     // }));
  //     // gtag('event', 'gt_click', {
  //     //   'event_category': `Reviews - Download CSV`,
  //     //   'event_label': `Download CSV`,
  //     //   'value': '',
  //     //   user_id: props.userprops.user.uid,
  //     // });
  //     window.gtag('event', 'gt_click', {
  //       'event_category': `Reviews - Download CSV`,
  //       'event_label': `Download CSV`,
  //       page_path: `${location.pathname}`,
  //       page_title: `Reputation - Reviews - ${locationName}`,
  //       gt_user_id: getUserGAId(userState.user),
  //       gt_location_id: urlParams.locationidentifier,
  //       gt_location: locationName,
  //     });
  //   }
  // };

  

  const renderTitleRow = (trend) => {
    return (
      <>
      <div className="prntTtlBx prntOly">
        <div className="title">
          {props.locationInfo.name}
        </div>
        <div className="rptDts">
          Period ({moment(dateFilterStartDate).format('Do MMM YYYY')}  -  {moment(dateFilterEndDate).format('Do MMM YYYY')})
        </div>
      </div>
      </>
    );
  };

  const renderDateFilterRow = (trend) => {
    const { has_csv_download_reviews } = props.locationInfo;
    return (
      <div className="dtFltBx">
        <div className="left">
          <div className="title">
            {/* Reviews/Ratings for a period */}
          </div>
        </div>
        <div className="dateFilter">
          {/* { has_csv_download_reviews &&
            <div className="dwnldCsv" onClick={onDownloadClick}>
              <img src={downloadIcon} />
              <p className="download">Download CSV</p>
              {
                reviews && reviews.getReviewsDumpInProgress &&
                <span>
                  <i className="fa fa-spinner fa-spin" />
                </span>
              }
            </div>
          } */}
          <div className="dateFilterLastMonth">
            <Select
              className="dateFilterLastMonthSelect"
              options={lastMonthOptions}
              onChange={onDateRangeLastMonthChange}
              value={dateRangeLastMonthValue}
            />
          </div>
          <div className="dateFilterRange">
            <RangePicker
              value={[moment(dateFilterStartDate), moment(dateFilterEndDate)]}
              onChange={onDateRangeChange}
              format="DD MMM YYYY"
              disabledDate={d => !d || d.isBefore(moment().subtract(3, 'months'))}
            />
          </div>
        </div>
      </div>
    );
  };



  const renderTrendTable = (trend) => {
    let byWhichPeriod = 'weekly';
    if (!trend || !trend[byWhichPeriod]) return null;
    const { weekly, monthly } = trend;
    let periodWiseData = [];
    if (byWhichPeriod === 'monthly') periodWiseData = monthly;
    if (byWhichPeriod === 'weekly') periodWiseData = weekly;
    // if (!primary.split || !primary.split.rating) return null;
    const { locationInfo } = props;
    // const ratingSplit = { ...primary.split.rating };
    const otaList = periodWiseData.length > 0 && periodWiseData[0].ratingOtaSplit ? Object.keys(periodWiseData[0].ratingOtaSplit) : [];
    
    let aspectMap = {}, aspectList = [];
    periodWiseData.forEach((el) => {
      if (el.sentiments && Object.keys(el.sentiments).length) {
        Object.keys(el.sentiments).forEach((itm) => {
          if (!aspectMap[itm]) { aspectMap[itm] = true; aspectList.push(itm); }
        });
      }
    });

    let feedbackQuestionsMap = {}, feedbackQuestionsList = [];
    periodWiseData.forEach((el) => {
      if (el.feedback_answers && Object.keys(el.feedback_answers).length) {
        Object.keys(el.feedback_answers).forEach((itm) => {
          if (!feedbackQuestionsMap[itm]) { feedbackQuestionsMap[itm] = el.feedback_answers[itm].question; feedbackQuestionsList.push(itm); }
        });
      }
    });

    return (
      <div className="trendAnalysis">
        <div className="compTbl">
          <div className="trndTblCol lgnd">
            <div className="locName"></div>
            <div className="totCount">Reviews</div>
            <div className="rtng">Rating</div>
            {
              [1,2,3,4,5].map((item) => {
                var starStyle = { "--rating": item };
                return (
                  <div>
                    <div className="rating">
                      <div className="stars">
                        <div className="compReviewStars" style={starStyle}></div>
                      </div>
                      {/* <div className="ratingValue">
                        {item} / 5
                      </div> */}
                    </div>
                  </div>
                )
              })
            }
            {
              otaList.map((item) => {
                return (
                  <div className="ota">{renderOTALogo(parseInt(item))}</div>
                )
              })
            }
            {
              aspectList.map((item) => {
                return (
                  <div className="ota">{item}</div>
                )
              })
            }
            <div className="totCount">Feedbacks</div>
            <div className="rtng">Feedbacks Positive</div>
            <div className="rtng">Feedbacks Negative</div>
            {
              feedbackQuestionsList.map((item) => {
                return (
                  <div className="ota">{feedbackQuestionsMap[item]}</div>
                )
              })
            }
          </div>
          {/* <div className="compTblCol prmy">
            <ToolTipWrapper placement="bottom" overlayClassName={'sbTooltip'}
            overlay={locationInfo.name}
            >
              <div className="locName">{locationInfo.name}</div>
            </ToolTipWrapper>
            <div className={"totCount"}>{primary.total_count || '-'}</div>
            <div className={"rtng" + (isRatingAOverallGreaterThanList(primary.average_rating, list, '', 'average_rating', 'average_rating-primary') ? ' grtst' : '')}>{primary.average_rating || '-'}</div>
            <div className={"rtng" + (isRatingAOverallGreaterThanList(primary.response_rate, list, '', 'response_rate', 'response_rate-primary') ? ' grtst' : '')}>{primary.response_rate || '-'}</div>
            {
              [1,2,3,4,5].map((item) => {
                return (
                  <div>
                    <div>{ratingSplit[item] || '-'}</div>
                  </div>
                )
              })
            }
          </div> */}
          {
            periodWiseData.map((item) => {
              // if (!item.data || !item.data.location_name || !item.data.split || !item.data.split.rating) {
              //   return null;
              // }
              const sentimentData = item.sentiments;
              const feedbackAnswersData = item.feedback_answers;
              const ratingSplit = { ...item.ratingSplit };
              const ratingOtaSplit = { ...item.ratingOtaSplit };
              return (
                <div className="trndTblCol">
                  <div className="locName">
                    {item.period}
                  </div>
                  <div className="totCount">{item.value || '-'}</div>
                  {/* <div className={"rtng" + (isRatingAOverallGreaterThanList(lData.average_rating, list, primary.average_rating, 'average_rating', 'average_rating-list') ? ' grtst' : '')}>{lData.average_rating || '-'}</div>
                  <div className={"rtng" + (isRatingAOverallGreaterThanList(lData.response_rate, list, primary.response_rate, 'response_rate', 'response_rate-list') ? ' grtst' : '')}>{lData.response_rate || '-'}</div> */}
                  <div className={"rtng"}>{item.rating || '-'}</div>
                  {
                    [1,2,3,4,5].map((item) => {
                      return (
                        <div>
                          <div>{ratingSplit[item] || '-'}</div>
                        </div>
                      )
                    })
                  }
                  {
                    otaList.map((item) => {
                      return (
                        <div>
                          <div>{ratingOtaSplit[item] || '-'}</div>
                        </div>
                      )
                    })
                  }
                  {
                    aspectList.map((item) => {
                      return (
                        <div>
                          <div>{sentimentData && sentimentData[item] ? sentimentData[item].overall || '-' : '-'}</div>
                        </div>
                      )
                    })
                  }
                  <div className="totCount">{item.feedback ? item.feedback.value || '-' : '-'}</div>
                  <div className="rtng">{item.feedback ? item.feedback.positive || '-' : '-'}</div>
                  <div className="rtng">{item.feedback ? item.feedback.negative || '-' : '-'}</div>
                  {
                    feedbackQuestionsList.map((item) => {
                      return (
                        <div>
                          <div>{feedbackAnswersData && feedbackAnswersData[item] ? feedbackAnswersData[item].out_of_100 || '-' : '-'} / {feedbackAnswersData && feedbackAnswersData[item] ? feedbackAnswersData[item].rating_count || '-' : '-'}</div>
                        </div>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    )
  };

  const renderRatingsTable = (periodWiseData) => {
    return (
      <div className="trendAnalysis">
        <div className="title">
          <div className="left">
            Reviews by Ratings
          </div>
          <div className="right byPrd">
            <div className={'byPrd' + (byPeriod === 'monthly' ? ' active' : '')} onClick={byMonthlyClick}>
              Monthly
            </div>
            <div className={'byPrd' + (byPeriod === 'weekly' ? ' active' : '')} onClick={byWeeklyClick}>
              Weekly
            </div>
            {
              showDaily &&
              <div className={'byPrd' + (byPeriod === 'daily' ? ' active' : '')} onClick={byDailyClick}>
                Daily
              </div>
            }
          </div>
        </div>
        <div className="compTbl">
          <div className="trndTblCol lgnd">
            <div className="yLbl"></div>
            <div className="yLbl">Reviews</div>
            <div className="yLbl">Rating</div>            
            {
              [1,2,3,4,5].map((item) => {
                var starStyle = { "--rating": item };
                return (
                  <div className="yLbl">
                    <div className="rating">
                      <div className="stars">
                        {
                          [...Array(item).keys()].map((itm) => {
                            return (
                              <img src={gtconfig.STAR_ICON} />
                            )
                          })
                        }
                        {/* <div className="compReviewStars" style={starStyle}></div> */}
                      </div>
                      {/* <div className="ratingValue">
                        {item} / 5
                      </div> */}
                    </div>
                  </div>
                )
              })
            }
          </div>
          {
            periodWiseData.map((item) => {
              const ratingSplit = { ...item.ratingSplit };
              return (
                <div className="trndTblCol">
                  <div className="yVal">
                    {item.period}
                  </div>
                  <div className="yVal">{item.value || '-'}</div>
                  <div className={"yVal"}>{item.rating || '-'}</div>
                  {
                    [1,2,3,4,5].map((item) => {
                      return (
                        <div className={"yVal"}>
                          <div>{ratingSplit[item] || '-'}</div>
                        </div>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    )
  };

  const renderOtaTable = (periodWiseData) => {
    let byWhichPeriod = 'weekly';
    const otaList = periodWiseData.length > 0 && periodWiseData[0].ratingOtaSplit ? Object.keys(periodWiseData[0].ratingOtaSplit) : [];
    
    return (
      <div className="trendAnalysis">
        <div className="title">
          Reviews by OTA
        </div>
        <div className="compTbl">
          <div className="trndTblCol lgnd">
            <div className="yLbl"></div>
            <div className="yLbl">Reviews</div>
            {
              otaList.map((item) => {
                return (
                  <div className="ota">{renderOTALogo(parseInt(item))}</div>
                )
              })
            }
          </div>
          {
            periodWiseData.map((item) => {
              const ratingOtaSplit = { ...item.ratingOtaSplit };
              return (
                <div className="trndTblCol">
                  <div className="locName">
                    {item.period}
                  </div>
                  <div className="yVal">{item.value || '-'}</div>
                  {
                    otaList.map((item) => {
                      return (
                        <div>
                          <div className="yVal">{ratingOtaSplit[item] || '-'}</div>
                        </div>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    );
  }

  const renderFeedbackTable = (periodWiseData) => {    
    let feedbackQuestionsPosMap = {}, feedbackQuestionsPosList = [];
    let feedbackQuestionsNegMap = {}, feedbackQuestionsNegList = [];
    periodWiseData.forEach((el) => {
      if (el.feedback_answers && Object.keys(el.feedback_answers).length) {
        Object.keys(el.feedback_answers).forEach((itm) => {
          if (el.feedback_answers[itm].positive) {
            if (!feedbackQuestionsPosMap[itm]) { feedbackQuestionsPosMap[itm] = el.feedback_answers[itm].question; feedbackQuestionsPosList.push(itm); }
          }
          if (el.feedback_answers[itm].negative) {
            if (!feedbackQuestionsNegMap[itm]) { feedbackQuestionsNegMap[itm] = el.feedback_answers[itm].question; feedbackQuestionsNegList.push(itm); }
          }
        });
      }
    });

    return (
      <div className="trendAnalysis">
        <div className="title">
          Feedbacks
        </div>
        <div className="compTbl">
          <div className="trndTblCol lgnd">
            <div className="yLbl"></div>
            <div className="yLbl">Feedbacks</div>
            <div className="yLbl">Feedbacks Positive</div>
            <div className="yLbl">Feedbacks Negative</div>
          </div>
          {
            periodWiseData.map((item) => {
              const feedbackAnswersData = item.feedback_answers;
              return (
                <div className="trndTblCol">
                  <div className="locName">
                    {item.period}
                  </div>
                  <div className="totCount">{item.feedback ? item.feedback.value || '-' : '-'}</div>
                  <div className="rtng">
                    {
                      item.feedback && item.feedback.value 
                      ?
                      <>
                        {item.feedback.positive || '-'}
                        {
                          item.feedback.positivePercent
                          ? <> ({item.feedback.positivePercent}%)</>
                          : null
                        }
                      </>
                      : null
                    }
                  </div>
                  <div className="rtng">
                    {
                      item.feedback && item.feedback.value 
                      ?
                      <>
                        {item.feedback.negative || '-'}
                        {
                          item.feedback.negativePercent
                          ? <> ({item.feedback.negativePercent}%)</>
                          : null
                        }
                      </>
                      : null
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
        {
          feedbackQuestionsPosList.length > 0 &&
          <>
          <div className="title">
            Follow up questions after positive feedback (score out of 100)
          </div>
          <div className="compTbl">
            <div className="trndTblCol lgnd">
              <div className="yLbl"></div>
              {
                feedbackQuestionsPosList.map((item) => {
                  return (
                    <div className="yLbl">{feedbackQuestionsPosMap[item]}</div>
                  )
                })
              }
            </div>
            {
              periodWiseData.map((item) => {
                const feedbackAnswersData = item.feedback_answers;
                return (
                  <div className="trndTblCol">
                    <div className="yVal">
                        {item.period}
                      </div>
                    {
                      feedbackQuestionsPosList.map((item) => {
                        return (
                          <div>
                            {/* <div>{feedbackAnswersData && feedbackAnswersData[item] ? feedbackAnswersData[item].out_of_100 || '-' : '-'} / {feedbackAnswersData && feedbackAnswersData[item] ? feedbackAnswersData[item].rating_count || '-' : '-'}</div> */}
                            <div>{feedbackAnswersData && feedbackAnswersData[item] ? feedbackAnswersData[item].out_of_100 || '-' : '-'}</div>
                          </div>
                        )
                      })
                    }
                  </div>
                )
              })
            }
          </div>
          </>
        }
        {
          feedbackQuestionsNegList.length > 0 &&
          <>
          <div className="title">
            Follow up questions after negative feedback (score out of 100)
          </div>
          <div className="compTbl">
            <div className="trndTblCol lgnd">
              <div className="yLbl"></div>
              {
                feedbackQuestionsNegList.map((item) => {
                  return (
                    <div className="yLbl">{feedbackQuestionsNegMap[item]}</div>
                  )
                })
              }
            </div>
            {
              periodWiseData.map((item) => {
                const feedbackAnswersData = item.feedback_answers;
                return (
                  <div className="trndTblCol">
                    <div className="yVal">
                        {item.period}
                      </div>
                    {
                      feedbackQuestionsNegList.map((item) => {
                        return (
                          <div>
                            {/* <div>{feedbackAnswersData && feedbackAnswersData[item] ? feedbackAnswersData[item].out_of_100 || '-' : '-'} / {feedbackAnswersData && feedbackAnswersData[item] ? feedbackAnswersData[item].rating_count || '-' : '-'}</div> */}
                            <div>{feedbackAnswersData && feedbackAnswersData[item] ? feedbackAnswersData[item].out_of_100 || '-' : '-'}</div>
                          </div>
                        )
                      })
                    }
                  </div>
                )
              })
            }
          </div>
          </>
        }
      </div>
    )
  };


  const renderFeedbacksWithText = (feedbacksWithText) => {
    if (!feedbacksWithText || !feedbacksWithText.length) return null;
    return (
      <div className="trendAnalysis">
        <div className="title">
          Negative feedbacks with description
        </div>
        <div className="listBox">
          {
            feedbacksWithText && feedbacksWithText.length
            ?
            feedbacksWithText.map((item) => {
                return <SingleFeedback key={`sfb_${item._id}`} data={item}
                  locationidentifier={urlParams.locationidentifier}
                  userState={userState}
                />
              })
            : null
          }
        </div>
      </div>
    );
  }


  if (process.env.NODE_ENV !== 'production') {
    console.log('Trend props: ', props);
  }
  
  const { trend } = props.reputation;
  if (!trend) return null;

  let byWhichPeriod = byPeriod;
  if (!trend || !trend[byWhichPeriod]) return null;
  const { daily, weekly, monthly, feedbacks_with_text } = trend;
  let periodWiseData = [];
  if (byWhichPeriod === 'monthly') periodWiseData = monthly;
  if (byWhichPeriod === 'weekly') periodWiseData = weekly;
  if (byWhichPeriod === 'daily') periodWiseData = daily;

  return (
    <>
      {/*
        comp.getInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      */}
      {
        trend.getInProgress &&
        <div className="gPnlSplLdng">
          <div className="innerWrap">
            <i className="fa fa-spinner fa-spin" />
            <img src={gtconfig.REPUTATION_LOADER_URL} />
          </div>
        </div>
      }
      <div className="gPScrlWrp flHt mk">
        <div className="gPg revsPg trendPg">
          { renderTitleRow(trend) }
          {/* { renderCurrentRatings(comp) } */}
          { renderDateFilterRow(trend) }
          <div className="revsBd">
            <div className="lt">
              
              { renderRatingsTable(periodWiseData) }
              { renderOtaTable(periodWiseData) }
              {/* { renderTrendTable(trend) } */}
              { renderFeedbackTable(periodWiseData) }
              { renderFeedbacksWithText(feedbacks_with_text) }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Trend;
