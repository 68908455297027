import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import queryString from 'query-string';

import { getDataAction, getDataClearAction } from '../../../redux/actions/getDataActions';
import { editDataAction, editDataClearAction } from '../../../redux/actions/editDataActions';

import { isGTUser, hasProductAccess } from '../../../helpers';

import closeIcon from '../../../img/icons/close.svg';

import '../style.css';


function Whatsapp(props) {
  const userState = useSelector((state) => state.userReducer);
  const appState = useSelector((state) => state.appReducer);
  const intgState = useSelector((state) => state.intgReducer);
  const snackState = useSelector((state) => state.snackReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();

  const [somethingConnected, setSomethingConnected] = useState(false);
  const [somethingDisconnected, setSomethingDisconnected] = useState(false);
  const [connectedLocationMid, setConnectedLocationMid] = useState('');

  const getData = () => {
    dispatch(getDataAction({
      op: 'get_intg_configure_data',
      params: {
      },
    }));
  };

  useEffect(() => {
    // SDK initialization
    // window.fbAsyncInit = function() {
    //   window.FB.init({
    //     appId            : '890627742985700',
    //   autoLogAppEvents : true,
    //   xfbml            : true,
    //   version          : 'v22.0'
    //   });
    // };
    function handleFbMessage(event) {
      if (event.origin !== "https://www.facebook.com" && event.origin !== "https://web.facebook.com") return;
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          console.log('message event: ', data); // remove after testing
          // your code goes here
        }
      } catch {
        console.log('message event: ', event.data); // remove after testing
        // your code goes here
      }
    }
    // Add event listener
    window.addEventListener("message", handleFbMessage);

    // Remove event listener on cleanup
    return () => window.removeEventListener("message", handleFbMessage);

  });

  const fbLoginCallback = (response) => {
    if (response.authResponse) {
      const code = response.authResponse.code;
      console.log('response: ', code); // remove after testing
      // your code goes here
    } else {
      console.log('response: ', response); // remove after testing
      // your code goes here
    }
  };

  const launchWhatsAppSignup = () => {
    window.FB.login(fbLoginCallback, {
      config_id: '479273318364973', // configuration ID goes here
      response_type: 'code', // must be set to 'code' for System User access token
      override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
      extras: {
        setup: {"business":{"id":null,"name":null,"email":null,"phone":{"code":null,"number":null},"website":null,"address":{"streetAddress1":null,"streetAddress2":null,"city":null,"state":null,"zipPostal":null,"country":null},"timezone":null},"phone":{"displayName":null,"category":null,"description":null},"preVerifiedPhone":{"ids":null},"solutionID":null,"whatsAppBusinessAccount":{"ids":null}},
        featureType: '',
        sessionInfoVersion: '2',
      }
    });
  };



  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Whatsapp location: ', location);
    }
    // dispatch(getDataClearAction({
    //   op: 'get_intg_configure_data',
    //   params: {}
    // }));
    // getData();
  }, []);

  // useEffect(() => {
  //   if (intgState.intg_data && intgState.intg_data.locations && intgState.intg_data.locations_list) {
  //     let connectedFound = false;
  //     intgState.intg_data.locations_list.forEach((item) => {
  //       if (intgState.intg_data.locations[item] && intgState.intg_data.locations[item].connected) {
  //         connectedFound = true;
  //         setConnectedLocationMid(item);
  //       }
  //     });
  //     setSomethingConnected(connectedFound);
  //   }
  // }, [intgState.intg_data])

  const onGetDataClick = (e) => {
    // getData();
  };

  // const onConnectClick = (data) => {
  //   if (somethingConnected) return;
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.log('RemoteLock onConnectClick data: ', data);
  //   }
  //   const queryJson = queryString.parse(location.search);
  //   const { code } = queryJson;
  //   if (code && data && data._id) {
  //     dispatch(editDataAction({
  //       op: 'edit_intg_configure_data',
  //       params: {
  //         code,
  //         partner: 'remote-lock',
  //         location__id: data._id,
  //       },
  //     }));
  //   }
  // };

  // const onDisconnectClick = (data) => {
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.log('RemoteLock onDisconnectClick data: ', data);
  //   }
  //   const queryJson = queryString.parse(location.search);
  //   // const { code } = queryJson;
  //   // if (code && data && data._id) {
  //     dispatch(editDataAction({
  //       op: 'edit_intg_configure_data',
  //       params: {
  //         disconnect: true,
  //         partner: 'remote-lock',
  //         location__id: data._id,
  //       },
  //     }));
  //   // }
  //   setSomethingDisconnected(true);
  // };

  if (process.env.NODE_ENV !== 'production') {
    console.log('Whatsapp: ', props);
    console.log('Whatsapp intgState: ', intgState);
  }

  // const { intg_data } = intgState;
  // if (!intg_data) return null;

  // const { locations_list, locations } = intg_data;

  // if (process.env.NODE_ENV !== 'production') {
  //   console.log('Whatsapp locations_list: ', locations_list);
  //   console.log('Whatsapp locations: ', locations);
  // }

  return (
    <div className="gPScrlWrp flHt allHme">
      <div className="gPWrp homeAllLocsPg">
        <div className="gPTle" onClick={onGetDataClick}>
          <button onClick={launchWhatsAppSignup}>Login with Facebook</button>
        </div>
        <div className="gBCtnt">
          {/* {
            locations_list && locations && locations_list.length
            ?
              locations_list.map((locationId) => {
                if (locations[locationId]) {
                  return (
                    <LocationRow data={locations[locationId]}
                      key={`lr_${locationId}`}
                      user={userState.user} appInfo={appState.appInfo}
                      onConnectClick={onConnectClick}
                      onDisconnectClick={onDisconnectClick}
                      somethingConnected={somethingConnected}
                    />
                  )
                }
                return null;
              })
            : null
          } */}
        </div>
        {/* {
          somethingConnected && !somethingDisconnected &&
          <div className="cntFollowUp">
            <p>Congratulations! Remote Lock is now connected. Please procees to Engage to retrieve and map locks.</p>
            <p><Link to={`/settings/locations/${connectedLocationMid}/hotel-details/remote-locks`}>Get Locks</Link></p>
          </div>
        } */}
      </div>
    </div>
  );

}

export default Whatsapp;
